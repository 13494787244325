<template>
  <div class="index_wrap">
    <h1 class="h_none">蓝灯鱼AI开放平台</h1>
    <h2 class="h_none">蓝灯鱼,lanternfish,蓝灯鱼商标,蓝灯鱼翻译,蓝灯鱼专利,AI,人工智能</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <div class="index_banner">
      <i class="index_banner_light"></i>
      <i class="index_banner_lightT"></i>
      <div class="index_banner_title">
        <p>
          招聘信息
        </p>
        <!--        <p>商标解决方案</p>-->
        <!--        <el-button @click="immediate" class="el_button">立即了解</el-button>-->
      </div>
    </div>

    <!--    招聘内容-->
    <div class="content">
      <div class="content_cen" v-for="(item,index) in dataTable" :key="index">
        <div class="title">
          <span>{{item.name}}</span>
          <span>{{item.address}}</span>
        </div>

        <ul v-for="(itm,ind) in item.describe" :key="ind">
          <li v-for="(it,ins) in itm" :key="ins">{{it}}</li>
        </ul>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
  import footers from '@/components/common/xfooter'
  export default {
    name: 'index',
    metaInfo: {
      title: '商标查询|专利翻译|知识产权管理系统-蓝灯鱼智能检索平台', // set a title
      meta: [{                 // set meta
        name: 'keyWords',
        content: '商标查询，商标近似查询，商标图形查询，专利翻译，专利查询，专利检索'
      }, {                 // set meta
        name: 'description',
        content: '蓝灯鱼是将人工智能技术运用到知识产权行业的平台。通过AI技术智能化商标查询,专利翻译,专利检索；提供在线使用和API接入等服务'
      }],
    },
    data () {
      return {
        dataTable:[
          {
            name:'NLP工程师',
            address:"工作地点：北京",
            describe:[
              [
                "岗位职责：",
                "-对专利检索算法进行测试和优化，或参与构建商标智能检索算法；",
                "-与产品经理和律师共同合作，改善产品品质；",
                "-负责专利知识图谱的构建工作；如知识表示、实体连接等工作；",
                "-负责深度提取和挖掘和知识图谱的大规模存储与管理；基于知识图谱的高效查询与挖掘分析；",
                "-负责相关算法的流程设计；对非结构数据进行清洗、转化、入库，将知识进行提取、分析与存储，包括但不限于从结构化/半结构化/非结构化的数据中抽取信息和知识；"
              ],
              [
                "任职要求：",
                "-计算机，数学相关专业的硕士/博士；本科学历以上，研二实习生；",
                "-熟悉深度学习/自然语言处理（文字分类/知识图谱/rnn/lstm/cnn/语义分析/主题模型/python/seq2seq/信息抽取）加分；",
                "-熟练使用Python，Linux平台，熟悉常用数据结构及算法，了解数据库处理；熟悉TensorFlow/PyTorch或任一深度学习框架，熟悉OpenCV，有GPU上模型训练经验；",
                "-熟悉知识抽取、知识融合和知识推理等基本算法和流程，有知识嵌入（KnowledgeEmbedding如TransE等）等深度学习相关经验者优先；",
                "-熟悉使用Neo4j、AllegroGraph、Virtuoso等图数据库中的一种，有图数据库开发经验者优先；",
                "-熟悉分词，实体识别等基本NLP工具的使用；"
              ]
            ]
          },
          {
            name:'产品经理',
            address:"工作地点：北京",
            describe:[
              [
                "岗位职责：",
                "-根据公司规划、设计，保障产品用户价值实现，包括：用户需求分析、业务流程优化、用户使用监控、产品功能设计、验收等；",
                "-产品规划：根据业务需求和分享，规划产品发展路线，设计产品服务模式，并定义相关功能模块；完成产品需求书，按需与相关部门合作，完成产品功能的最终实现；",
                "-设计产品开发方案，发起研发项目，与研发团队紧密配合，快速、高效推动研发，撰写出明晰的需求开发档案，跟进相关部门配合，并保证产品准时更新上线；",
                "-对上线的产品进行运营情况做监控和分析，持续推荐产品优化；"
              ],
              [
                "任职要求：",
                "-完整跟过不少于2个以上的项目需求分析，1个主导的互联网产品或网站的设计、运营、市场的工作经验。对知识产权行业有一定了解；",
                "-熟练掌握axure等原型制作工具，会写PRD档案；",
                "-具有出色的产品演讲、培训能力；具有出色的沟通、工作推动能力；具有出色的文案、创意能力；有强烈的细节完美主义感；",
                "-结果导向，良好的执行力和团队协助能力，能承受一定工作压力；",
                "-了解科技，逻辑思维清晰、行业变化敏感，有创新思维，良好的逻辑和数据分析能力；",
                "-有较好的英文阅读能力者；口语能力强优先考虑；"
              ]
            ]
          },
          {
            name:'机器翻译工程师',
            address:"工作地点：北京",
            describe:[
              [
                "岗位职责：",
                "-负责专业领域的工业级别的深度神经网络翻译模型的开发，参与模型设计、训练、评价和部署；",
                "-按计划运行并监督神经网络翻译模型的训练过程；",
                "-记录训练日志，对模型进行常规测试、分析、比对；",
                "-操作数据库提取训练语句对，对训练数据进行日常维护，筛选，标引，整理等；",
                "-负责将模型部署到生产环境的工作；"
              ],
              [
                "任职要求：",
                "-电脑相关专业的硕士或以上学历，实习生研二优先；",
                "-有参与神经网络翻译的项目经验，有过工业级的深度神经网络项目部署经验；",
                "-熟悉Tensorflow、Pytorch模型的原理，有使用经验；",
                "-Linux环境操作能力，Python代码程序设计经验，SQL数据库使用经验；"
              ]
            ]
          },
          {
            name:'测试实习生',
            address:"工作地点：北京",
            describe:[
              [
                "岗位职责：",
                "-运用深度学习模型进行商标分类识别；",
                "-根据大量商标信息对算法进行测试和优化；",
                "-与产品经理和律师共同合作，改善产品品质；"
              ],
              [
                "任职要求：",
                "-硕士/博士；电脑相关专业；优秀大学生也可；",
                "-研究方向为图像处理/深度学习；",
                "-熟练C/C++/Python语言，Linux平台；",
                "-熟悉TensorFlow/PyTorch或任一深度学习框架，熟悉OpenCV，有GPU上模型训练经验；",
                "-了解数据库处理；"
              ]
            ]
          },
          {
            name:'NLP工程師',
            address:"工作地點：香港",
            describe:[
              [
                "崗位職責：",
                "-對專利檢索算灋進行測試和優化，或參與構建商標智能檢索算灋；",
                "-與產品經理和律師共同合作，改善產品品質；",
                "-負責專利知識圖譜的構建工作；如知識表示、實體連結等工作；",
                "-負責深度選取和挖掘和知識圖譜的大規模存儲與管理；基於知識圖譜的高效査詢與挖掘分析；",
                "-負責相關算灋的流程設計；對非結構數據進行清洗、轉化、入庫，將知識進行選取、分析與存儲，包括但不限於從結構化/半結構化/非結構化的數據中抽取資訊和知識；"
              ],
              [
                "任職要求：",
                "-電腦，數學相關專業的碩士/博士；本科學歷以上，研二實習生；",
                "-熟悉深度學習/自然語言處理（文字分類/知識圖譜/rnn/lstm/cnn/語義分析/主題模型/python/seq2seq/資訊抽取）加分；",
                "-熟練使用Python，Linux平臺，熟悉常用資料結構及算灋，瞭解資料庫處理；熟悉TensorFlow/PyTorch或任一深度學習框架，熟悉OpenCV，有GPU上模型訓練經驗；",
                "-熟悉知識抽取、知識融合和知識推理等基本算灋和流程，有知識嵌入（KnowledgeEmbedding如TransE等）等深度學習相關經驗者優先；",
                "-熟悉使用Neo4j、AllegroGraph、Virtuoso等圖資料庫中的一種，有圖資料庫開發經驗者優先；",
                "-熟悉分詞，實體識別等基本NLP工具的使用；"
              ]
            ]
          },
          {
            name:'產品經理',
            address:"工作地點：香港",
            describe:[
              [
                "崗位職責：",
                "-根據公司規劃、設計，保障產品用戶價值實現，包括：用戶需求分析、業務流程優化、用戶使用監控、產品功能設計、驗收等；",
                "-產品規劃：根據業務需求和分享，規劃產品發展路線，設計產品服務模式，並定義相關功能模組；完成產品需求書，按需與相關部門合作，完成產品功能的最終實現；",
                "-設計產品開發方案，發起研發項目，與研發團隊緊密配合，快速、高效推動研發，撰寫出明晰的需求開發檔案，跟進相關部門配合，並保證產品準時更新上線；",
                "-對上線的產品進行運營情况做監控和分析，持續推薦產品優化；"
              ],
              [
                "任職要求：",
                "-完整跟過不少於2個以上的項目需求分析，1個主導的互聯網產品或網站的設計、運營、市場的工作經驗。對知識產權行業有一定瞭解；",
                "-熟練掌握axure等原型製作工具，會寫PRD檔案；",
                "-具有出色的產品演講、培訓能力；具有出色的溝通、工作推動能力；具有出色的文案、創意能力；有强烈的細節完美主義感；",
                "-結果導向，良好的執行力和團隊協助能力，能承受一定工作壓力；",
                "-瞭解科技，邏輯思維清晰、行業變化敏感，有創新思維，良好的邏輯和資料分析能力；",
                "-有較好的英文閱讀能力者；口語能力强優先考慮；"
              ]
            ]
          },
          {
            name:'機器翻譯工程師',
            address:"工作地點：香港",
            describe:[
              [
                "崗位職責：",
                "-負責專業領域的工業級別的深度神經網路翻譯模型的開發，參與模型設計、訓練、評估和部署；",
                "-按計畫運行並監督神經網路翻譯模型的訓練過程；",
                "-記錄訓練日誌，對模型進行常規測試、分析、比對；",
                "-操作資料庫選取訓練語句對，對訓練數據進行日常維護，篩選，標引，整理等；",
                "-負責將模型部署到生產環境的工作；"
              ],
              [
                "任職要求：",
                "-電腦相關專業的碩士或以上學歷，實習生研二優先；",
                "-有參與神經網路翻譯的項目經驗，有過工業級的深度神經網路項目部署經驗；",
                "-熟悉Tensorflow、Pytorch模型的原理，有使用經驗；",
                "-Linux環境操作能力，Python程式碼程式設計經驗，SQL資料庫使用經驗；"
              ]
            ]
          },
          {
            name:'測試實習生',
            address:"工作地點：香港",
            describe:[
              [
                "崗位職責：",
                "-運用深度學習模型進行商標分類識別；",
                "-根據大量商標資訊對算灋進行測試和優化；",
                "-與產品經理和律師共同合作，改善產品品質；"
              ],
              [
                "任職要求：",
                "-碩士/博士；電腦相關專業；優秀大學生也可；",
                "-研究方向為圖像處理/深度學習；",
                "-熟練C/C++/Python語言，Linux平臺；",
                "-熟悉TensorFlow/PyTorch或任一深度學習框架，熟悉OpenCV，有GPU上模型訓練經驗；",
                "-瞭解資料庫處理；"
              ]
            ]
          },
        ],
        activeName: 'second'
      }
    },
    mounted() {
    },
    components: {
      footers
    },
    methods: {
      handleClick(tab, event) {
      }
    }
  }
</script>

<style scoped lang="less">
  .index_wrap {
    background: #fff;
    .index_banner {
      width: 100%;
      height: 560px;
      background: url("@{imgUrl}patent/trademark.png") no-repeat center
        center/auto 100%;
      position: relative;
      .index_banner_light {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}patent/trademark.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }
      .index_banner_lightT {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: url("@{imgUrl}patent/trademark.png") no-repeat center
          center/auto 100%;
        animation: blink 4s infinite;
        -webkit-animation: blink 4s infinite;
        -moz-animation: blink 4s infinite;
        -o-animation: blink 4s infinite;
      }
      .index_banner_title {
        position: absolute;
        left: 110px;
        top: 193px;
        z-index: 3;
        i {
          display: block;
          width: 343px;
          margin-bottom: 30px;
          img {
            display: block;
            width: 100%;
          }
        }
        p:nth-child(1){
          font-size: 40px;
          color: rgb( 255, 255, 255 );
        }
        p:nth-child(2){
          margin-top: 29px;
          font-size: 20px;
          color: rgb( 255, 255, 255 );
        }
        .el_button{
          margin-top: 30px;
          width: 123px;
          height: 41px;
          font-size: 18px;
          color: rgb( 27, 48, 191 );
          font-weight: bold;
        }
      }
    }

    .content{
      padding: 88px 135px 0 112px;
      .content_cen{
        .title{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 71px;
          span:nth-of-type(1){
            font-size: 26px;
            font-weight: bold;
            color: rgb( 51, 51, 51 );
          }
          span:nth-of-type(2){
            font-size: 20px;
            font-weight: 500;
            color: rgb( 51, 51, 51 );
          }
        }
        ul{
          margin-top: 32px;
          li{
            line-height: 42px;
            font-size: 16px;
            color: rgb( 102, 102, 102 );

            &:nth-of-type(1){
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
        padding-bottom: 90px;
      }

    }

  }
  @media screen and (max-width: 1600px) {
    .index_wrap {
      .index_banner {
        height: 460px;
        .index_banner_title {
          /*top: 150px;*/
          /*left: 133px;*/
        }
      }

      .learnMore{
        margin-top: 110px!important;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .index_wrap {
      .index_banner {
        height: 602px;
        .index_banner_title {
          /*top: 210px;*/
          /*left: 34px;*/
        }
      }
    }
  }

  .arrow-enter {
    transform: translateX(-30px);
  }
  .arrow-enter-active {
    transition: all 0.8s ease;
  }
</style>
<style lang="less">
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  .el-carousel__mask {
    background-color: transparent;
  }

  .el-carousel__indicators--outside{
    display: none;
  }

  /*.el-carousel__item:nth-child(2n) {*/
  /*  background-color: #99a9bf;*/
  /*}*/

  /*.el-carousel__item:nth-child(2n+1) {*/
  /*  background-color: #d3dce6;*/
  /*}*/

  .index_swiper_dialog {
    .swiper-box {
      .swiper-slide {
        img {
          width: 100%;
        }
      }
    }
  }
</style>
